import React, { useState } from "react";

function Table(props) {
  const { data, columns, customClass,footer } = props;

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Set the number of items to display per page

  // Calculate total pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Get the data for the current page
  const paginatedData =
  
    data &&
    data?.length > 0 &&
    data?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Function to handle page changes
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getColor = (clr) => {
    switch (clr) {
      case "Shedule":
        return "pending-button w-fit-content p-1 px-2";
      case "In-Active":
        return "custom-deactive-button w-fit-content p-1 px-2";
      case "Active":
        return "font-green custom-active-button px-2";
      case "Rejected":
        return "custom-deactive-button w-fit-content p-1 px-2";
      case "Pending":
        return "pending-button w-fit-content p-1 px-2";
      case "Select":
        return "select-button w-fit-content p-1 px-2";
      case "DeSelect":
        return "select-button w-fit-content p-1 px-2";
      default:
        return "w-fit-content p-1 px-2";
    }
  };

  return (
    <div className="table-border sidebar-bg w-100 fixed-table-height">
      <table
        className={`fixed-table tickets-table table table-borderless ${customClass}`}
      >
        <thead className="th-color small-font text-center">
          <tr className="small-font th-color text-center">
            {columns?.map((column, index) => (
              <th key={index} className="text-center small-font th-color p-3">
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="td-color w-100">
          {paginatedData?.length > 0 &&
            paginatedData?.map((item, rowIndex) => (
              <tr key={rowIndex} className="small-font td-color text-center">
                {columns.map((column, colIndex) => {
                  return (
                    <td
                      key={colIndex}
                      className=" text-center d-felx align-items-center"
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className={
                            column?.header === "DESCRIPTION"
                              ? "scroll-table-div"
                              : column?.clr
                              ? getColor(item[column.field])
                              : ""
                          }
                        >
                          <div>{item[column.field]}</div>
                        </div>
                       
                      </div>
                    </td>
                  );
                })}
              </tr>
              
              
            ))}
            
        </tbody>

        {footer && (
          <tfoot className="text-center">
             <tr className="small-font th-color text-center">
            {footer?.map((column, index) => (
              <th key={index} className="text-center small-font th-color p-3">
                {column.header}
              </th>
            ))}
          </tr>
          </tfoot>
        )}
        
        
      </table>
      
    
      <div className=" my-2 d-flex justify-content-between align-items-center">
        <div className="medium-font blue-clr px-2">
          Showing {itemsPerPage * (currentPage - 1) + 1} to{" "}
          {Math.min(currentPage * itemsPerPage, data.length)} of {data.length}{" "}
          entries
        </div>
        <div className="pagination-controls mx-2">
          <div
            className="pagination-btn blue-clr small-font mx-2 previous px-2 py-1 br-5"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </div>
          {totalPages > 0 &&
            Array.from({ length: totalPages }, (_, i) => (
              <div
                key={i}
                className={`pagination-btn px-2 py-1 br-5 small-font mx-1 ${
                  currentPage === i + 1 ? "active" : ""
                }`}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </div>
            ))}

          {/* {[...Array(totalPages)] &&
            [...Array(totalPages)]?.length &&
            [...Array(totalPages)]?.map((_, i) => (
              <div
                key={i}
                className={`pagination-btn small-font mx-2 ${
                  currentPage === i + 1 ? "active" : ""
                }`}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </div>
            ))} */}
          <div
            className="pagination-btn mx-1 small-font next blue-clr px-2 py-1 br-5"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;