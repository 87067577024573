import React, { useEffect, useInsertionEffect, useRef, useState } from "react";
import Table from "../table/Table";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import PackagePopup from "../Popups/PackagePopup";
import { axiosInstance, call, call_to_access, Signed } from "../../config/axios";
import { GENERATE_SIGNED_URL, GET_ALL_CALL_PACKAGES, IMAGE_UPLOADING_URL } from "../../config/endpoints";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
const PackageLiveLine = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [selectedDuration, setSelectedDuration] = useState();
  const [selectedNumber, setSelectedNumber] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const options = [
    "All Package",
    "Calling Package",
    "Live Streaming",
    "Line Package",
  ];
  const [selectedOption, setSelectedOption] = useState("All Package");
  const [selectedOptionBtnColor, setSelectedOptionBtnColor] = useState("30");
  const [selectedOptionBtnColorCalling, setSelectedOptionBtnColorCalling] = useState("Hourly");
  const [showAddWebPopup, setShowAddWebPopup] = useState(false);
  const [openPackageData, setOpenPackageData] = useState({});
  const dropdownRef = useRef(null);
  const [packageData, setPackageData] = useState([])

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("")
  const [createCallingPackage, setCreateCallingPackage] = useState({
    CallDuration: "",
    PackageCost: "",
    Members: "",
    CallJoinUsers: "",
    PersonalMeetings: "",
    Meetings: "",
    Discount: "",
    Website: "",
    CountryName: "",
    UploadImage: "",
    PackageDuration: "",
    PackageName: ""
  });
  useEffect(() => {
    const getAllPackages = async () => {
      try {
        const res = await axiosInstance.get("/package/GetAllPackages");
        if (res.data) {
          setPackageData(res.data)
        }
        if (res.data.error) {
          console.error("API Error:", res.data.message);
        }
      } catch (err) {
        console.error("API Error:", err);
      }
    }
    getAllPackages()
  }, [])
  const handleClose = () => {
    setShowEditModal(false);
    setShowDeactivateModal(false);
    setSelectedPackage(null);
  };
  const handleEditClick = (pkg) => {
    setSelectedPackage(pkg);
    if (pkg) {
      setCreateCallingPackage({
        CallDuration: pkg.callDuration || "", // Adjust field names accordingly
        PackageCost: pkg.packageCost || "",
        Members: pkg.members || "",
        CallJoinUsers: pkg.callJoinUsers || "", // Ensure the correct key is used
        PersonalMeetings: pkg.personalMeetings || "",
        Meetings: pkg.meetings || "",
        Discount: pkg.discount || "",
        UploadImage: pkg.uploadImage || null, // Add this if the package includes a URL for an image
        PackageDuration: pkg.packageDuration,
        PackageName: pkg.packageName,
        CountryName: pkg.countryName,
        Website: pkg.website
      });
      setShowEditModal(true);
    }
  };
  const handleDeactivate = async () => {
    try {
      console.log(selectedPackage, "package");

      // Making the API call
      const res = await axiosInstance.post(`/package/DeactivatePackage/${selectedPackage.packageId}`);
      console.log(res.data, "success");
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 401) {
          console.error("Unauthorized: You need to log in again.");
        } else if (status === 500) {
          console.error("Server Error: Please try again later.");
        } else {
          console.error(`Unhandled Error: ${error.response.data.message || "Something went wrong"}`);
        }
      } else if (error.request) {
        console.error("No response received from the server. Check your network.");
      } else {
        console.error(`Request Error: ${error.message}`);
      }
    }
  };
  const [showModal, setShowModal] = useState(false)
  // const onConfirmDeactivation = async (pkg) => {

  //   setSelectedPackage(pkg);
  //   setShowModal(true)
  //   setShowDeactivateModal(true);
  // };
  // const DeactivateModal = () => (

  //   <Modal
  //     show={showModal}
  //     onHide={() => setShowModal(false)}
  //     centered
  //     className="match-declaration-modal"
  //   >
  //     <div className="sidebar-bg th-color p-2 d-flex flex-column radius">
  //       {/* Close Icon */}
  //       <span className="flex-end font-24 cursor-pointer">
  //         <IoClose onClick={() => setShowModal(false)} />
  //       </span>

  //       {/* Modal Header */}
  //       <div className="d-flex flex-column">
  //         <div className="flex-center fw-500 large-font clr-white">Confirm Deactivation</div>
  //         <div className="p-3">
  //           <div className="blue-shade-bg br-20 p-1 text-center">
  //             <span className="text-sm">
  //               Are you sure you want to deactivate the package
  //             </span>
  //           </div>
  //         </div>
  //       </div>

  //       {/* Action Buttons */}
  //       <div className="d-flex justify-content-between gap-2 p-2">
  //         <button
  //           className="add-button rounded px-2 py-2 w-30 medium-font"
  //           onClick={handleDeactivate}
  //         >
  //           Confirm
  //         </button>
  //         <button
  //           className="add-button rounded px-2 py-2 w-30 medium-font"
  //           onClick={() => setShowModal(false)}
  //         >
  //           Cancel
  //         </button>
  //       </div>
  //     </div>
  //   </Modal>
  // );
  function isWithin24Hours(createdAt) {
    const currentTime = new Date(); 
    const createdTime = new Date(createdAt); 

    const timeDifference = currentTime - createdTime;

    const hoursDifference = timeDifference / (1000 * 60 * 60);

    return hoursDifference <= 24;
  }
  const formatPackageData = (packages, handleEdit) =>
    packages.map((pkg) => ({
      date: (
        <div className="d-flex flex-column">
          <span>{new Date(pkg.createdAt).toLocaleDateString()}</span>
          <span>{new Date(pkg.createdAt).toLocaleTimeString()}</span>
        </div>
      ),
      pkg_id: pkg.packageId,
      inactive_date: (
        <div className="d-flex flex-column">
          <span>{"N/A"}</span>
          <span>{"N/A"}</span>
        </div>
      ),
      pkg_name: (
        <div className="d-flex flex-column">
          <span>{pkg.packageName}</span>
        </div>
      ),
      pkg_cost: (
        <div className="d-flex flex-column">
          <span>{pkg.packageCost.toLocaleString()} INR</span>
        </div>
      ),
      pkg_hours: (
        <div className="d-flex flex-column">
          <span>{pkg.callDuration} Minutes</span>
        </div>
      ),
      ltd_mem: pkg.members,
      discount: `${pkg.discount}%`,
      card_details: `No of members allowed: ${pkg.callJoinUsers}`,
      status: (
        <div className={pkg.status ? "clr-green light-blue p-1 br-10" : "clr-red light-blue p-1 br-10"}>
          {pkg.status ? "Active" : "Inactive"}
        </div>
      ),
      action: (
        <div className="d-flex gap-2">
          {isWithin24Hours(pkg.createdAt) ? <FaEdit
            className="icon-action text-primary cursor-pointer mt-2"
            onClick={() => handleEditClick(pkg)}
            title="Edit Package"
          /> : <div> {"--"}</div>}

          {/* <FaTrashAlt
            className="icon-action text-danger cursor-pointer"
            onClick={() => onConfirmDeactivation(pkg)}
            title="Deactivate Package"
          /> */}
        </div>
      ),

    }));

  const dropdownData = {
    number: [
      { id: 1, label: "1", value: "1" },
      { id: 2, label: "2", value: "2" },
      { id: 3, label: "3", value: "3" },
      { id: 4, label: "4", value: "4" },
      { id: 5, label: "5", value: "5" },
    ],
    duration: [
      { id: 1, label: "Hourly", value: "hourly" },
      { id: 2, label: "Monthly", value: "monthly" },
      { id: 3, label: "Yearly", value: "yearly" },
    ],
    createPackage: [
      { id: 1, label: "Standard", value: "standard" },
      { id: 2, label: "Silver", value: "silver" },
      { id: 3, label: "Gold", value: "gold" },
      { id: 4, label: "Diamond", value: "diamond" },
      { id: 5, label: "VIP", value: "vip" },
    ],
    createPackageLiveLine: [
      { id: 1, label: "Standard", value: "standard" },
      { id: 2, label: "Silver", value: "silver" },
      { id: 3, label: "Gold", value: "gold" },
      { id: 4, label: "Diamond", value: "diamond" },
      { id: 5, label: "VIP", value: "vip" },
      { id: 6, label: "Live Streaming", value: "live_streaming" },
      { id: 7, label: "Line Streaming", value: "line_streaming" },
    ],
    headings: [
      { id: 5, label: "Add Logo", value: "Add Logo" },
      { id: 5, label: "Add Offer text", value: "Add Offer text" },
      { id: 5, label: "Create Package", value: "Create Package" },
    ],
  };

  const handleOptionClickBtnColor = (option) => {
    setSelectedOptionBtnColor(option);
  };
  const handleOptionClickBtnColorCalling = (option) => {
    setSelectedOptionBtnColorCalling(option);
  };

  const toggleDropdownList = () => {
    setIsOpen((prev) => !prev);
  };
  const formattedData = formatPackageData(packageData);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleDropdown = (heading) => {
    setActiveDropdown((prev) => (prev === heading ? null : heading));
    setSelectedOffer("");
    setSelectedDuration("");
    setSelectedNumber("");
  };

  const handleOfferClick = (offer, position) => {
    setSelectedOffer({ offer, position });
    setSelectedDuration("");
    setSelectedNumber("");
  };
  const handleDurationClick = (duration, position) => {
    console.log(duration, position, "dusration")
    setSelectedDuration({ duration, position });
    setSelectedNumber("");
    setOpenPackageData(position, selectedOffer.offer, selectedDuration.duration);
    if (position !== "Add Offer text") {
      setShowAddWebPopup(true);
    } else {
      setShowAddWebPopup(false);
    }
  };

  const handleSelectNumber = (id, label) => {
    setShowAddWebPopup(true);
  };

  const cols = [
    { header: <div className="fw-600">DATE</div>, field: "date" },
    { header: <div className="fw-600">PKG ID</div>, field: "pkg_id" },
    {
      header: <div className="fw-600">INACTIVE DATE</div>,
      field: "inactive_date",
    },
    { header: <div className="fw-600">PKG NAME</div>, field: "pkg_name" },
    { header: <div className="fw-600">PKG COST</div>, field: "pkg_cost" },
    { header: <div className="fw-600">PKG HOURS</div>, field: "pkg_hours" },
    { header: <div className="fw-600">LTD MEM</div>, field: "ltd_mem" },
    { header: <div className="fw-600">DISCOUNT</div>, field: "discount" },
    {
      header: <div className="fw-600">CARD DETAILS</div>,
      field: "card_details",
    },
    { header: <div className="fw-600">STATUS</div>, field: "status" },
    { header: <div className="fw-600">ACTION</div>, field: "action" },
  ];
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
      setSelectedOffer(null);
      setSelectedDuration(null);
    }
  };
  const packageDataToPass = {
    ...selectedOffer,
    ...selectedDuration
  }



  const handleInputChangeCalling = (e) => {
    const { name, value } = e.target;
    setCreateCallingPackage((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const handleFileUpload = async (e) => {
  //   const posetNewId = new Date().getTime();
  //   const file = e.target.files[0];
  //   if (file) {
  //     await call(IMAGE_UPLOADING_URL, {
  //       register_id: `${posetNewId}`,
  //       event_type: "user_profile_image",
  //       folder_name: "package-images",
  //     })
  //       .then(async (res) => {
  //         let url = res?.data?.data?.result?.signed_url;
  //         console.log(url, "generated url")
  //         if (url) {
  //           setImageUrl(url)
  //           setCreateCallingPackage((prevState) => ({
  //             ...prevState,
  //             UploadImage: url,
  //           }));
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });

  //   }

  // };

  // const handleSuccessPopup = () => {
  //   setLineLiveCreation(true)
  // };
  const [updateApiError, setUpdateError] = useState('')
  const handleCreateCallingPackage = async () => {

    console.log(createCallingPackage, "updated");
    try {
      if (selectedPackage.packageId) {
        const res = await axiosInstance.put(`/package/UpdatePackage/${selectedPackage.packageId}`, createCallingPackage)
        if (res.status === 200 || res.status === 201) {
          setShowEditModal(false);
        }
        if (res.data && res.data.error) {
          console.error("API Error:", res.data.message);
        }
      }

    }
    catch (err) {
      if (err.response) {
        console.error("API Error Response:", err.response.data);
        setUpdateError(err.response.data)
      } else if (err.request) {
        console.error("No response received:", err.request);
      } else {
        console.error("Error during request setup:", err.message);
      }
    }
  };

  return (
    <div className="w-100 clr-white medium-font">
      <div className="w-100 d-flex flex-between">
        <div className="medium-font px-2">All Package</div>
        <div
          className="w-50 py-2 d-flex flex-between position-relative medium-font fw-500"
          ref={dropdownRef}
        >
          {["Add Logo", "Add Offer text", "Create Package"].map(
            (heading, index) => (
              <div className="w-30 pointer position-relative" key={index}>
                <div
                  className="package-btn w-100 position-relative my-2 d-flex flex-between"
                  onClick={() => toggleDropdown(heading)}
                >
                  {heading}
                  {activeDropdown === heading ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </div>
                {activeDropdown === heading && (
                  <div
                    className="day-button pkg-dropdown br-10"
                    ref={dropdownRef}
                  >
                    {(heading === "Create Package"
                      ? dropdownData.createPackageLiveLine
                      : dropdownData.createPackage
                    ).map((item) => (
                      <div
                        key={item.id}
                        className="border-bottom-white py-2 d-flex hover-container"
                        onClick={() => handleOfferClick(item.label, heading)}
                      >
                        <span className="blue-line"></span>
                        <span className="px-2 pointer">{item.label}</span>
                      </div>
                    ))}
                  </div>
                )}

                {selectedOffer?.position === heading && (
                  <div
                    className="day-button pkg-dropdown-duration br-10 position-absolute "
                    ref={dropdownRef}
                  >
                    {dropdownData.duration.map((item) => (
                      <div
                        key={item.id}
                        className="border-bottom-white py-2 d-flex hover-container"
                        onClick={() => handleDurationClick(item.label, heading)}
                      >
                        <span className="blue-line"></span>
                        <span className="px-2 pointer">{item.label}</span>
                      </div>
                    ))}
                  </div>
                )}
                {selectedDuration?.position === heading &&
                  heading !== "Create Package" &&
                  heading !== "Add Logo" && (
                    <div
                      className={`pkg-dropdown-number day-button br-10 position-absolute`}
                      ref={dropdownRef}
                    >
                      {dropdownData.number.map((item) => (
                        <div
                          key={item.id}
                          className="border-bottom-white py-2 d-flex hover-container"
                          onClick={() =>
                            handleSelectNumber(item.label, heading)
                          }
                        >
                          <span className="blue-line"></span>
                          <span className="px-2 pointer">{item.label}</span>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            )
          )}
        </div>
      </div>
      <div className="w-100 package-container">
        <div className="package-bg p-2 br-10 w-20 border-white">
          <div
            className="d-flex flex-between pointer"
            onClick={toggleDropdownList}
          >
            <span>{selectedOption}</span>
            <MdKeyboardArrowDown />
          </div>
          {isOpen && (
            <div className="dropdown-list blue-theme br-10">
              {options.map((option, index) => (
                <div
                  key={index}
                  className="border-white pointer p-2"
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="my-2 py-1 fa-fileinvo-doll-icon pkg-bg w-10 text-center br-10">
          <span>{selectedOption}</span>
        </div>
        {selectedOption === "Live Streaming" && (
          <div className="d-flex w-50 medium-font pointer">
            <div
              className={`w-50 p-2 br-10 text-center ${selectedOptionBtnColor === "30"
                ? "selected-option"
                : "light-blue"
                }`}
              onClick={() => handleOptionClickBtnColor("30")}
            >
              30 Days
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${selectedOptionBtnColor === "90"
                ? "selected-option"
                : "light-blue"
                }`}
              onClick={() => handleOptionClickBtnColor("90")}
            >
              90 Days
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${selectedOptionBtnColor === "180"
                ? "selected-option"
                : "light-blue"
                }`}
              onClick={() => handleOptionClickBtnColor("180")}
            >
              180 Days
            </div>
          </div>
        )}
        {selectedOption === "Line Package" && (
          <div className="d-flex w-50 medium-font pointer">
            <div
              className={`w-50 p-2 br-10 text-center ${selectedOptionBtnColor === "30"
                ? "selected-option"
                : "light-blue"
                }`}
              onClick={() => handleOptionClickBtnColor("30")}
            >
              30 Days
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${selectedOptionBtnColor === "90"
                ? "selected-option"
                : "light-blue"
                }`}
              onClick={() => handleOptionClickBtnColor("90")}
            >
              90 Days
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${selectedOptionBtnColor === "180"
                ? "selected-option"
                : "light-blue"
                }`}
              onClick={() => handleOptionClickBtnColor("180")}
            >
              180 Days
            </div>
          </div>
        )}
        {selectedOption === "Calling Package" && (
          <div className="d-flex w-50 medium-font pointer">
            <div
              className={`w-50 p-2 br-10 text-center ${selectedOptionBtnColorCalling === "Hourly"
                ? "selected-option"
                : "light-blue"
                }`}
              onClick={() => handleOptionClickBtnColorCalling("Hourly")}
            >
              Hourly
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${selectedOptionBtnColorCalling === "Monthly"
                ? "selected-option"
                : "light-blue"
                }`}
              onClick={() => handleOptionClickBtnColorCalling("Monthly")}
            >
              Monthly
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${selectedOptionBtnColorCalling === "Yearly"
                ? "selected-option"
                : "light-blue"
                }`}
              onClick={() => handleOptionClickBtnColorCalling("Yearly")}
            >
              Yearly
            </div>
          </div>
        )}
      </div>
      <Table columns={cols} data={formattedData} />
      {showAddWebPopup && (
        <PackagePopup
          showAddWebPopup={showAddWebPopup}
          setShowAddWebPopup={setShowAddWebPopup}
          openPackageData={openPackageData}
          data={packageDataToPass}
        />
      )}
      {/* {DeactivateModal()} */}
      {showEditModal ?
        <div className="modal fade bd-example-modal-lg container w-75 p-2">
          <Modal
            className="match-share-modal payment-modal p-2"
            centered
            show={showEditModal} onHide={() => setShowEditModal(false)} >
            <Modal.Body>
              <div className="modal-content-wrapper p-3">
                <div className="w-100 d-flex">
                  <div className="w-50">
                    <div className="small-font ms-1 my-2">
                      PackageName
                    </div>
                    <input
                      type="text"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                      placeholder="Enter"
                      name="PackageName"
                      value={createCallingPackage?.PackageName}
                      onChange={handleInputChangeCalling}
                      disabled={true}
                    />
                  </div>
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      PackageDuration
                    </div>
                    <input
                      type="text"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                      placeholder="Enter"
                      name="PackageDuration"
                      value={createCallingPackage?.PackageDuration}
                      onChange={handleInputChangeCalling}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-50">
                    <div className="small-font ms-1 my-2">
                      Package Hour <span className="red-clr">*</span>
                    </div>
                    <input
                      type="text"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                      placeholder="Enter"
                      name="CallDuration"
                      value={createCallingPackage?.CallDuration}
                      onChange={handleInputChangeCalling}
                    />
                  </div>
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Call join users <span className="red-clr">*</span>
                    </div>
                    <input
                      type="text"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                      placeholder="Enter"
                      name="CallJoinUsers"
                      value={createCallingPackage?.CallJoinUsers}
                      onChange={handleInputChangeCalling}
                    />
                  </div>

                </div>

                <div className="w-100 d-flex">
                  <div className="w-50">
                    <div className="small-font ms-1 my-2">
                      Members
                    </div>
                    <input
                      type="text"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                      placeholder="Enter"
                      name="Members"
                      value={createCallingPackage?.Members}
                      onChange={handleInputChangeCalling}
                      disabled={true}
                    />
                  </div>
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Price
                    </div>
                    <input
                      type="text"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                      placeholder="Enter"
                      name="PackageCost"
                      value={createCallingPackage?.PackageCost}
                      onChange={handleInputChangeCalling}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="w-100 d-flex">
                  <div className="w-50">
                    <div className="small-font ms-1 my-2">
                      Personal meetings
                    </div>
                    <input
                      type="text"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                      placeholder="Enter"
                      name="PersonalMeetings"
                      value={createCallingPackage?.PersonalMeetings}
                      onChange={handleInputChangeCalling}
                      disabled={true}
                    />
                  </div>
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Meetings
                    </div>
                    <input
                      type="text"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                      placeholder="Enter"
                      name="Meetings"
                      value={createCallingPackage?.Meetings}
                      onChange={handleInputChangeCalling}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="w-100 d-flex">
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Discount
                    </div>
                    <input
                      type="text"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                      placeholder="Enter discount"
                      name="Discount"
                      value={createCallingPackage?.Discount}
                      onChange={handleInputChangeCalling}
                      disabled={true}
                    />
                  </div>

                  {/* <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Upload Logo/File <span className="red-clr">*</span>
                    </div>
                    <label
                      htmlFor="file-upload"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex align-items-center justify-content-between all-none rounded mt-2"
                    >
                      {createCallingPackage?.UploadImage ? (
                        <span>{createCallingPackage?.UploadImage.name || "File Uploaded"}</span>
                      ) : (
                        <span>Choose a file</span>
                      )}
                      <span className="clr-blue">Browse</span>
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      className="d-none"
                      accept="image/*"
                      onChange={handleFileUpload}
                    />
                  </div> */}
                </div>
                <div className="d-flex justify-content-between w-100 mt-3 mb-4">
                  <button
                    className="add-button rounded px-2 py-2 w-30 medium-font"
                    onClick={() => setShowEditModal(false)}>
                    Close
                  </button>
                  <button
                    className="add-button rounded px-2 py-2 w-40 medium-font" onClick={handleCreateCallingPackage}>
                    Save Changes
                  </button>
                </div>
                <span className="red-clr medium-font fw-600">
                  {updateApiError}
                </span>
              </div>
            </Modal.Body>
          </Modal>
        </div> : null
      }
    </div >
  );
};

export default PackageLiveLine;