import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./styles.css";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";
import { axiosInstance } from "../../config/axios";

function AddWebsitePopup(props) {
  const {
    showAddWebPopup,
    setShowAddWebPopup,
    setStatus,
    modalMode,
    editFormData,
    setWebsites,
  } = props;

  const [acceptClick, setAcceptClick] = useState(false);

  const [formData, setFormData] = useState({
    Name: "",
    URL: "",
    Role: "",
    Origin: "",
    Username: "",
  });

  useEffect(() => {
    if (modalMode === "edit" && editFormData) {
      setFormData(editFormData);
    }
  }, [modalMode, editFormData]);

  const handleAddWebPopupClose = () => {
    setShowAddWebPopup(false);
    // setFormData('');
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    
  };

  const handleCreateWebsite = async () => {
    try {
      const url =
        modalMode === "add"
          ? "/Team/CreateWebsite"
          : `/Team/UpdateWebsite/${editFormData.Id}`;

      const res = await axiosInstance({
        method: modalMode === "add" ? "POST" : "PUT",
        url,
        data: formData,
      });

      if (res.status === 200) {
        setAcceptClick(true);
        setTimeout(() => {
          setAcceptClick(false);
          setShowAddWebPopup(false);
        }, 2000);
        const websitesResponse = await axiosInstance.get(`/Team/GetWebsites`);
        if (websitesResponse.data?.status === 200) {
          setWebsites(websitesResponse.data?.data || []);
        }
      } else {
        console.error("API Error:", res.data?.message || "Unexpected Error");
      }

      setStatus((prev) => !prev);
      setFormData({
        Name: "",
        URL: "",
        Role: "",
        Origin: "",
        Username: "",
      });
    } catch (err) {
      console.error("API Error:", err);
    }
  };

  return (
    <div className="modal fade bd-example-modal-lg container w-75">
      <Modal
        show={showAddWebPopup}
        onHide={handleAddWebPopupClose}
        centered
        className="match-share-modal payment-modal"
      >
        <Modal.Header closeButton>
          <div className="w-100 text-center mt-4">
            {modalMode === "add" ? <h6>Add Website</h6> : <h6>Update Website</h6>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 px-4">
            <div>
              <div className="small-font ms-1">Website Name</div>
              <input
                type="text"
                name="Name"
                className="w-100 input-btn-bg px-2 py-2 all-none rounded mt-2"
                placeholder="Enter Website Name"
                value={formData.Name}
                onChange={handleInputChange}
              />
            </div>
            <div className="mt-2">
              <div className="small-font ms-1">Website URL</div>
              <input
                type="text"
                name="URL"
                className="w-100 input-btn-bg px-2 py-2 all-none rounded mt-2"
                placeholder="Enter Website URL"
                value={formData.URL}
                onChange={handleInputChange}
              />
            </div>
            <div className="d-flex justify-content-center w-100 mt-3 mb-4">
              <button
                className="add-button rounded px-2 py-2 w-50 medium-font"
                onClick={handleCreateWebsite}
              >
                {modalMode === "add" ? "ADD" : "EDIT"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <MatchSubmitPopup
        header={
          modalMode === "add"
            ? "Website Added Successfully"
            : "Website Updated Successfully"
        }
        state={acceptClick}
        setState={setAcceptClick}
        setStatus={setStatus}
      />
    </div>
  );
}

export default AddWebsitePopup;
