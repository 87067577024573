import { useEffect, useState } from "react";
import Table from "../table/Table";
import { AiOutlineEye } from "react-icons/ai";
import PackageViewPoup from "../Popups/PackageViewPoup";
import {
  GET_ADMIN_PACKAGE_REQUEST,
  PACKAGE_APPROVE_REJECT,
  BULK_PACKAGE_APPROVE_REJECT,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import axios from "axios";

function Tickets() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [requestedPackages, setRequestedPackages] = useState([]);
  const [popupData, setPopupData] = useState([]);
  const [transactionData, setTransactionData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [status, setStatus] = useState(false);
  const [showPackageUpgrade, setShowPackageUpgrade] = useState(false);
  const registerId = localStorage.getItem("register_id")

  const handleActiveIndex = (index) => {
    setActiveIndex(index);
  };

  const PACKAGE_TICKET_BUTTONS = [
    {
      buttonName: "All",
    },
    {
      buttonName: "PACKAGE TICKETS",
    },
    // {
    //   buttonName: "CASINO/SPORTS TICKETS",
    // },
    {
      buttonName: "LIVE STREAM TICKETS",
    },
    {
      buttonName: "LINE TICKETS",
    },
    {
      buttonName: "PAYMENT GATEWAY TICKETS",
    },
  ];

  const handleSuccessfullPopup = async (
    transaction_id,
    type,
    status,
    reason
  ) => {
    const BASE_URL = "http://we2call.top2tours.com/api/Ticket";
  
  
    const url = `${BASE_URL}/${status}/${transaction_id}`;
  
    setIsProcessing(true);
    setStatus(status);
    try {
      // Call the API with PUT request
      const response = await axios(url, {
        method: "PUT",
        body: JSON.stringify({
          register_id: "company",
          reason,
          company_id: "company_id",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        await getRequestedPackages();
        setShowPackageUpgrade(false);
        setTimeout(() => setIsProcessing(false), 2000);
      }
    } catch (err) {
      console.error("Error updating transaction status:", err);
      setIsProcessing(false);
    } finally {
      setStatus("");
    }
  };
  

  const getRequestedPackages = async () => {
    await call(GET_ADMIN_PACKAGE_REQUEST, {
      register_id: "company",
    })
      // await axiosInstance.get(`/Ticket/GetTickets/${registerId}`)
      .then((res) => {
        setRequestedPackages(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const fetchData = async () => {
      await getRequestedPackages();
    };
    fetchData();
  }, []);

  const handlePackageUpgrade = (item) => {
    setPopupData(item);
    setTransactionData(item);
    setShowPackageUpgrade(true);
    setStatus((prev) => !prev);
  };

  const cols = [
    { header: "USERNAME & ROLE", field: "usernameAndrole" },
    { header: "DATE & TIME", field: "dateAndTime" },
    { header: "TRX ID", field: "trxid" },
    { header: "PACKAGE TRX", field: "package" },
    // { header: "CHIPS", field: "chips" },
    { header: "DISCOUNT", field: "discount" },
    { header: "PAY AMOUNT", field: "pkgamnt" },
    { header: "STATUS", field: "status", clr: true },
  ];

  const cols1 = [
    { header: "USERNAME & ROLE", field: "usernameAndrole" },
    { header: "DATE & TIME", field: "dateAndTime" },
    { header: "TRX ID", field: "trxid" },
    { header: "PACKAGE TRX", field: "package" },
    // { header: "CHIPS", field: "chips" },
    { header: "DISCOUNT", field: "discount" },
    { header: "PAY AMOUNT", field: "pkgamnt" },
    { header: "VIEW", field: "icon" },
    { header: "STATUS", field: "status", clr: true },
  ];

  const Paymentcols = [
    { header: "USERNAME & ROLE", field: "usernameAndrole" },
    { header: "DATE & TIME", field: "dateAndTime" },
    { header: "TRX ID", field: "trxid" },
    { header: "PACKAGE TRX", field: "package" },
    { header: "DISCOUNT", field: "discount" },

    { header: "CURRENCY", field: "curr" },

    { header: "CURRENCY AMT", field: "curramt" },

    { header: "AMOUNT IN INR", field: "amtinrs" },

    { header: "PAY AMOUNT", field: "pkgamnt" },
    { header: "VIEW", field: "icon" },
    { header: "STATUS", field: "status", clr: true },
  ];


  const paymentGatewayTicketDetails = [
    {
      usernameAndrole: (<div className="d-flex flex-column">
        <div className="clr-yellow">superadmin2000</div>
        <div className="clr-black">superadmin</div>

      </div>),
      dateAndTime: (<div className="d-flex flex-column">
        <div>6/1/2024</div>
        <div>18:31:13</div>

      </div>),
      trxid: (<div>trx-20240601130113746</div>),
      package: (<div>228000</div>),
      discount: (<div>10</div>),
      curr: (<div>USd $</div>),
      curramt: (<div>1000</div>),
      amtinrs: (<div>800</div>),
      pkgamnt: (<div>20000</div>),
      icon: (
        <AiOutlineEye
          className="eye-icon-size cursor-pointer"
          onClick={() => handlePackageUpgrade()}
        />),
      status: (<div className="rounded px-2 py-1 approved-btn">Approved</div>)

    },
    {
      usernameAndrole: (<div className="d-flex flex-column">
        <div className="clr-yellow">superadmin2000</div>
        <div className="clr-black">superadmin</div>

      </div>),
      dateAndTime: (<div className="d-flex flex-column">
        <div>6/1/2024</div>
        <div>18:31:13</div>

      </div>),
      trxid: (<div>trx-20240601130113746</div>),
      package: (<div>228000</div>),
      discount: (<div>10</div>),
      curr: (<div>USd $</div>),
      curramt: (<div>1000</div>),
      amtinrs: (<div>800</div>),
      pkgamnt: (<div>20000</div>),
      icon: (
        <AiOutlineEye
          className="eye-icon-size cursor-pointer"
          onClick={() => handlePackageUpgrade()}
        />),
      status: (<div className="rounded px-2 py-1 rejected-btn">Rejected</div>)

    },
    {
      usernameAndrole: (<div className="d-flex flex-column">
        <div className="clr-yellow">superadmin2000</div>
        <div className="clr-black">superadmin</div>

      </div>),
      dateAndTime: (<div className="d-flex flex-column">
        <div>6/1/2024</div>
        <div>18:31:13</div>

      </div>),
      trxid: (<div>trx-20240601130113746</div>),
      package: (<div>228000</div>),
      discount: (<div>10</div>),
      curr: (<div>USd $</div>),
      curramt: (<div>1000</div>),
      amtinrs: (<div>800</div>),
      pkgamnt: (<div>20000</div>),
      icon: (
        <AiOutlineEye
          className="eye-icon-size cursor-pointer"
          onClick={() => handlePackageUpgrade()}
        />),
      status: (<div className="rounded px-2 py-1 pending-btn">Pending</div>)

    },
  ];

  let packageTrxTotal = 0,
    payAmountTotal = 0;

  let packageTrxTotal1 = 0,
    payAmountTotal1 = 0;

  let packageTrxTotal2 = 0,
    payAmountTotal2 = 0;

  let packageTrxTotal3 = 0,
    payAmountTotal3 = 0;

  const modifiedTicketDetails =
    requestedPackages?.length > 0 &&
    requestedPackages?.map(
      (item) => (
        (packageTrxTotal += item?.summary?.total_package_cost
          ? +item?.summary?.total_package_cost
          : 0),
        (payAmountTotal += item?.summary?.final_package_cost
          ? +item?.summary?.final_package_cost
          : 0),
        {
          usernameAndrole: (
            <div>
              <div> {item?.summary.requester_name}</div>
              <div className="role-color">{item?.summary?.requester_role}</div>
            </div>
          ),
          dateAndTime: (
            <div>
              <div>{item?.created_date}</div>
              <div>{item?.created_time}</div>
            </div>
          ),
          trxid: item?.transaction_id,
          package: item?.summary.total_package_cost || 0,
          chips: 0,
          discount:
            item?.summary?.total_package_cost -
            item?.summary?.final_package_cost || 0,
          pkgamnt: item?.summary?.final_package_cost || 0,
          status:
            (item?.status === "Approved" && (
              <div className="rounded p-1 approved-btn">Approved</div>
            )) ||
            (item?.status === "Reject" && (
              <div className="rounded p-1 rejected-btn">Reject</div>
            )) ||
            (item?.status === "pending" && (
              <div className="rounded p-1 px-2 pending-btn">Pending</div>
            )),
        }
      )
    );

  const modifiedTicketDetails1 =
    requestedPackages?.length > 0 &&
    requestedPackages.map(
      (item) => (
        (packageTrxTotal1 += item?.summary.total_packages_cost
          ? +item?.summary.total_packages_cost
          : 0),
        (payAmountTotal1 += item?.summary.final_package_cost
          ? +item?.summary.final_package_cost
          : 0),
        {
          ...item,
          usernameAndrole: (
            <div>
              <div>{item?.summary.requester_name}</div>
              <div className="role-color">{item?.summary.requester_role}</div>
            </div>
          ),
          dateAndTime: (
            <div>
              <div> {item?.created_date}</div>
              <div>{item?.created_time}</div>
            </div>
          ),
          trxid: item?.transaction_id,
          package: item?.summary.total_packages_cost || 0,
          chips: 0,
          discount: 0,
          pkgamnt: item?.summary.final_package_cost || 0,
          status:
            (item?.status === "Approved" && (
              <div className="rounded px-2 py-1 approved-btn">Approved</div>
            )) ||
            (item?.status === "Reject" && (
              <div className="rounded px-3 py-1 rejected-btn">Reject</div>
            )) ||
            (item?.status === "pending" && (
              <div className="rounded px-2 py-1 pending-btn">Pending</div>
            )),
          icon: (
            <AiOutlineEye
              className="eye-icon-size cursor-pointer"
              onClick={() => handlePackageUpgrade(item)}
            />
          ),
          summary: item?.summary,
        }
      )
    );

  return (
    <div className="p-4 w-100">
      <h6 className="h6 font-grey">Tickets</h6>
      <div className="sidebar-bg rounded">
        <div className="d-flex w-75 align-items-center justify-content-between white-space">
          {PACKAGE_TICKET_BUTTONS?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleActiveIndex(index)}
              className={`medium-font accounts-box font-grey px-3 py-2 rounded text-center m-3 cursor-pointer  ${activeIndex === index ? "active" : ""
                }`}
            >
              {item.buttonName}
            </div>
          ))}
        </div>
        {activeIndex === 0 && (
          <>
            <Table columns={cols} data={modifiedTicketDetails} />
            <div className="home-bg-clr py-2">
              <div className="sidebar-bg rounded th-color large-font d-flex align-items-center justify-content-around p-1">
                <div className="medium-font">
                  PACKAGE TRX TOTAL :
                  <span
                    className={`px-1 ${packageTrxTotal >= 0 ? "clr-green" : "red-text"
                      }`}
                  >
                    {packageTrxTotal}
                  </span>
                </div>
                <div className="medium-font">
                  PAY AMOUNT TOTAL :
                  <span
                    className={`px-1 ${payAmountTotal >= 0 ? "clr-green" : "red-text"
                      }`}
                  >
                    {payAmountTotal}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {activeIndex === 1 && (
          <>
            <Table columns={cols1} data={modifiedTicketDetails1} />
            <div className="home-bg-clr py-2">
              <div className="sidebar-bg rounded th-color large-font d-flex align-items-center justify-content-around p-1">
                <div className="medium-font">
                  PACKAGE TRX TOTAL :
                  <span
                    className={`px-1 ${packageTrxTotal1 >= 0 ? "clr-green" : "red-text"
                      }`}
                  >
                    {packageTrxTotal1}
                  </span>
                </div>
                <div className="medium-font">
                  PAY AMOUNT TOTAL :
                  <span
                    className={`px-1 ${payAmountTotal1 >= 0 ? "clr-green" : "red-text"
                      }`}
                  >
                    {payAmountTotal1}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {activeIndex === 2 && (
          <>
            <Table columns={cols1} data={modifiedTicketDetails1} />
            <div className="home-bg-clr py-2">
              <div className="sidebar-bg rounded th-color large-font d-flex align-items-center justify-content-around p-1">
                <div className="medium-font">
                  PACKAGE TRX TOTAL :
                  <span
                    className={` px-1 ${packageTrxTotal1 >= 0 ? "clr-green" : "red-text"
                      }`}
                  >
                    {packageTrxTotal1}
                  </span>
                </div>
                <div className="medium-font">
                  PAY AMOUNT TOTAL :
                  <span
                    className={` px-1 ${payAmountTotal1 >= 0 ? "clr-green" : "red-text"
                      }`}
                  >
                    {payAmountTotal1}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {activeIndex === 3 && (
          <>
            <Table columns={cols1} data={modifiedTicketDetails1} />
            <div className="home-bg-clr py-2">
              <div className="sidebar-bg rounded th-color large-font d-flex align-items-center justify-content-around p-1">
                <div className="medium-font">
                  PACKAGE TRX TOTAL :
                  <span
                    className={`px-1 ${packageTrxTotal1 >= 0 ? "clr-green" : "red-text"
                      }`}
                  >
                    {packageTrxTotal1}
                  </span>
                </div>
                <div className="medium-font">
                  PAY AMOUNT TOTAL :
                  <span
                    className={`px-1 ${payAmountTotal1 >= 0 ? "clr-green" : "red-text"
                      }`}
                  >
                    {payAmountTotal1}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {activeIndex === 4 && (
          <>
            <Table columns={Paymentcols} data={paymentGatewayTicketDetails} />
            <div className="home-bg-clr py-2">
              <div className="sidebar-bg rounded th-color large-font d-flex align-items-center justify-content-around p-1">
                <div className="medium-font">
                  PACKAGE TRX TOTAL :
                  <span
                    className={`px-1 ${packageTrxTotal1 >= 0 ? "clr-green" : "red-text"
                      }`}
                  >
                    {packageTrxTotal1}
                  </span>
                </div>
                <div className="medium-font">
                  PAY AMOUNT TOTAL :
                  <span
                    className={`px-1 ${payAmountTotal1 >= 0 ? "clr-green" : "red-text"
                      }`}
                  >
                    {payAmountTotal1}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <PackageViewPoup
        requestedPackages={popupData}
        showPackageUpgrade={showPackageUpgrade}
        transactionData={transactionData}
        isProcessing={isProcessing}
        status={status}
        handleSuccessfullPopup={handleSuccessfullPopup}
        setShowPackageUpgrade={setShowPackageUpgrade}
      />
    </div>
  );
}

export default Tickets;
