import Totalaccount from "../home/Totalaccount";
import AddWebsitePopup from "../Popups/AddWebsitePopup";
import { useEffect, useState } from "react";
import {axiosInstance, call } from "../../config/axios";
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdEdit,
  MdDelete,
} from "react-icons/md";
import Table from "../table/Table";
import { BsEye } from "react-icons/bs";
import DeletePopup from "../broadcasting/DeletePopup";


function Addwebsites({ selectedRegisterId }) {
  const [allWebsites, setAllWebsites] = useState([]);
  const [filteredWebsites, setFilteredWebsites] = useState([]);
  const [status, setStatus] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [editFormData, setEditFormData] = useState("");
  console.log("editFormData", editFormData)
  const [showAddWebPopup, setShowAddWebPopup] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [showWebsiteList, setShowWebsiteList] = useState(false);
  const [delWebsitePopup, setDelWebsitePopup] = useState(false);
  const [editWebsitePopup, setEditWebsitePopup] = useState(false);
  const [editModeActive, setEditModeActive] = useState(false);
  const handleShowAddWebPopup = () => {
    setShowAddWebPopup(true);
  };

  const searchContent = (value) => {
    setSearchText(value);
    const filteredSearchText = allWebsites.filter((res) =>
      res?.web_url?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredWebsites(filteredSearchText);
  };

  const hanldeDelWebsiteModal = () => {
    setDelWebsitePopup(!delWebsitePopup);
  };

  const handleEditWebsiteModal = async (id) => {
    try {
      const res = await axiosInstance.get(`/Team/GetWebsite/${id}`);

      if (res.data) {
        const websiteDetails = {
          Id: res.data.id || "",
          Name: res.data.name || "",
          URL: res.data.url || "",
          Role: res.data.role || "",
          Origin: res.data.origin || "",
          Username: res.data.username || "",
        };

        setEditFormData(websiteDetails);
        setEditModeActive(true);
        setEditWebsitePopup(true);
      }
    } catch (err) {
      console.error("Error fetching website details:", err);
    }
  };


  const cols = [
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10 fw-600">WEBSITE NAME</div>
          {/* <div>
            <div>
              <MdKeyboardArrowUp className="fs-6" />
            </div>
            <MdKeyboardArrowDown className="fs-6 margintop-10" />
          </div> */}
        </div>
      ),
      field: "web_url",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">IS USED</div>
          {/* <div>
            <div>
              <MdKeyboardArrowUp className="fs-6" />
            </div>
            <MdKeyboardArrowDown className="fs-6 margintop-10" />
          </div> */}
        </div>
      ),
      field: "location",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">USER NAME</div>
          {/* <div>
            <div>
              <MdKeyboardArrowUp className="fs-6" />
            </div>
            <MdKeyboardArrowDown className="fs-6 margintop-10" />
          </div> */}
        </div>
      ),
      field: "user_name",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">ROLE</div>
          {/* <div>
            <div>
              <MdKeyboardArrowUp className="fs-6" />
            </div>
            <MdKeyboardArrowDown className="fs-6 margintop-10" />
          </div> */}
        </div>
      ),
      field: "Role",
      clr: true,
    },
  ];

  const dummyWebsites = [
    {
      web_url: "www.we2call.com",
      location: ["India-Hyderabad"],
      user_name: ["Ashok"],
      Role: <div className="bs-percircl-icon">Director</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
  ];

  const colsList = [
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10 fw-600">WEBSITE NAME</div>
        </div>
      ),
      field: "website",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">WEBSITE URL</div>
        </div>
      ),
      field: "url",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">ACTION</div>
        </div>
      ),
      field: "action",
    },
  ];

  const WebsiteLists = () => {
    if (!allWebsites.length) return [];
    return allWebsites.map((website) => ({
      website: website.name || "N/A",
      url: website.url || "N/A",
      action: (
        <div className="d-flex">
          <MdEdit
            className="font-16 me-2 clr-yellow"
            onClick={() => handleEditWebsiteModal(website.id)}
          />
          <MdDelete
            className="font-16 clr-yellow"
            onClick={() => hanldeDelWebsiteModal(website.id)}
          />
        </div>
      ),
    }));
  };

  const getAllWebsites = async () => {

    try {
      const res = await axiosInstance.get(`/Team/GetWebsites`)
      setAllWebsites(res?.data || []);
    } catch (error) {
      console.error("Error fetching websites:", error);
    }
  };

  useEffect(() => {
    getAllWebsites();
  }, [status]);

  const handleWebsiteList = () => {
    setShowWebsiteList(!showWebsiteList);
  };

  return (
    <div className="p-4 w-100">
      <Totalaccount />
      <div className="sidebar-bg rounded">
        <div className="d-flex row">
          <h6 className="h6 font-grey m-0 px-4 pt-3">Add Website</h6>
          <div className=" d-flex justify-conten-around">
            <div className="containaer-fluid ps-2 w-20">
              <form className="d-flex" role="search">
                <input
                  className="search-width m-1 mt-3 p-2 text-white w-100 sidebar-bg borderr rounded medium-font"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchText}
                  onChange={(e) => searchContent(e.target.value)}
                />
              </form>
            </div>

            <div
              className="row justify-content-md-center mt-2 ms-1 p-1 "
              onClick={() => handleShowAddWebPopup()}
            >
              <div
                className="active text-white col-md-auto small-font justify-content-between p-2 px-4 m-1 cursor-pointer ">
                +Add Website
              </div>
            </div>

            <div className="row justify-content-md-center mt-2 ms-1 p-1">
              <div
                className="active text-white col-md-auto small-font justify-content-between p-2 px-4 m-1 cursor-pointer "
                onClick={handleWebsiteList}
              >
                Website Lists
              </div>
            </div>
          </div>
        </div>
        <div className="font-grey ps-2 py-1">{`${showWebsiteList === true ? "Websites Lists" : "Website Names"
          }`}</div>
      </div>
      {editWebsitePopup ? (
        <AddWebsitePopup
          showAddWebPopup={editWebsitePopup}
          setShowAddWebPopup={setEditWebsitePopup}
          setStatus={setStatus}
          modalMode="edit"
          editFormData={editFormData} 
        />
      ) : (
        <AddWebsitePopup
          showAddWebPopup={showAddWebPopup}
          setShowAddWebPopup={setShowAddWebPopup}
          setStatus={setStatus}
          modalMode="add"
        />
      )}

      <DeletePopup
        show={delWebsitePopup}
        setShow={setDelWebsitePopup}
        title={"Are you sure you wnat to delete this website?"}
      />

      {showWebsiteList ? (
        <Table columns={colsList} data={WebsiteLists()} />
      ) : (
        <Table columns={cols} data={dummyWebsites} />
      )}
    </div>
  );
}

export default Addwebsites;
