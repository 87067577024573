export const app_base_url =
  "https://zkj7sxivl5.execute-api.ap-southeast-1.amazonaws.com/prod/";

export const chat_base_url =
  "wss://i35qazph78.execute-api.ap-southeast-1.amazonaws.com/prod/";
export const app_base_url_3 =
  "http://we2call.top2tours.com/api";
  
  export const Signed_url =
  "https://gddigb51ed.execute-api.us-east-2.amazonaws.com/prod";

export const app_base_url_2 =
  "http://we2call.top2tours.com/api/";
  
