import React, { useRef, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { ADD_WEBSITE, CREATE_PACKAGES, GENERATE_SIGNED_URL, PRESIGNED_URL } from "../../config/endpoints";
import { axiosInstance, call, call_to_access, Signed, uploadFilesToS3 } from "../../config/axios";
import { IoIosCloudUpload } from "react-icons/io";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";
function PackagePopup(props) {
  const { showAddWebPopup, setShowAddWebPopup, setStatus, openPackageData, data } =
    props;
  const [acceptClick, setAcceptClick] = useState(false);
  const [lineLiveCreation, setLineLiveCreation] = useState(false)
  const [formData, setFormData] = useState({
    register_id: "company",
    web_url: "",
  });

  const handleSuccessPopup = () => {
    setLineLiveCreation(true)
  }

  const handleAddWebPopupClose = () => {
    setShowAddWebPopup(false);
    setFormData('')
  };
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleCreateWebsite = async () => {
    try {
      const res = await call(ADD_WEBSITE, formData);
      if (res.data.status === 200) {
        setAcceptClick(true);
        handleSuccessPopup()
        setTimeout(() => {
          setAcceptClick(false);
          setShowAddWebPopup(false);
        }, 2000);
      }
      if (res.data.error) {
        console.error("API Error:", res.data.message);
      } else {
        setAcceptClick(true);
        handleAddWebPopupClose();
        setShowAddWebPopup(false);
        setStatus((prev) => !prev);
        setFormData({
          register_id: "company",
          web_url: "",
        });
      }
    } catch (err) {
      console.error("API Error:", err);
    }
  };

  const [singedUrl, setSignedUrl] = useState("");
  const [createCallingPackage, setCreateCallingPackage] = useState({
    PackageName: data?.offer,
    PackageDuration: data?.duration,
    CallDuration: "",
    PackageCost: "",
    Members: "",
    CallJoinUsers: "",
    PersonalMeetings: "",
    Meetings: "",
    Discount: "",
    Website: "",
    CountryName: "",
    UploadImage: "https://we2-call-images.s3.us-east-2.amazonaws.com/package-images/1704780474475.png"
    // UploadImage: singedUrl
  });
  // const handleFileUpload = async (e) => {
  //   console.log("file")
  //   const posetNewId = new Date().getTime();
  //   const file = e.target.files[0];
  //   // if (file) {
  //   //   await Signed(PRESIGNED_URL, {
  //   //     register_id: `${posetNewId}`,
  //   //     event_type: "user_profile_image",
  //   //     folder_name: "posters-images",
  //   //   })
  //   //     .then(async (res) => {
  //   //       let url = res?.data?.data?.result?.signed_url;
  //   //       setSignedUrl(url);
  //   //       console.log(url, "hello url")
  //   //       if (url) {
  //   //         setCreateCallingPackage((prevState) => ({
  //   //           ...prevState,
  //   //           UploadImage: url,
  //   //         }));
  //   //       }

  //   //     })
  //   //     .catch((err) => {
  //   //       ;
  //   //       console.log(err);
  //   //     });

  //   // }
  //   console.log(file, "file")
  //   if (file) {
  //     try {
  //       const [url] = await uploadFilesToS3([file]);
  //       console.log(url, "generated url")
  //       if (!url) throw new Error("Image upload failed");
  //       setSignedUrl(url)
  //     } catch (err) {
  //       // showError(err.message);
  //       console.log("error", err)
  //     }
  //   }

  // };
  const [updateApiError, setUpdateError] = useState('')
  // };
  const handleInputChangeCalling = (e) => {
    const { name, value } = e.target;

    // Dynamically update the state based on the input name
    setCreateCallingPackage({
      ...createCallingPackage,
      [name]: value,
    });
  };
  const handleCreateCallingPackage = async () => {
    const payload = {
      PackageName: createCallingPackage.PackageName,
      PackageDuration: createCallingPackage.PackageDuration,
      CallDuration: Number(createCallingPackage.CallDuration),
      PackageCost: Number(createCallingPackage.PackageCost),
      Members: Number(createCallingPackage.Members),
      CallJoinUsers: Number(createCallingPackage.CallJoinUsers),
      PersonalMeetings: Number(createCallingPackage.PersonalMeetings),
      Meetings: Number(createCallingPackage.Meetings),
      Discount: Number(createCallingPackage.Discount),
      Website: createCallingPackage.Website,
      CountryName: createCallingPackage.CountryName,
      UploadImage: createCallingPackage.UploadImage,
    };

    // Validate required fields
    if (!payload.PackageName || !payload.PackageDuration || !payload.UploadImage) {
      alert("Please fill all required fields.");
      return;
    }

    console.log(payload, "payload");

    try {
      const res = await axiosInstance.post("/package/CreatePackage", payload);

      if (res.status === 200) {
        console.log(res, "API Response");
        setAcceptClick(true);
        handleSuccessPopup();

        setTimeout(() => {
          setAcceptClick(false);
          setShowAddWebPopup(false);
        }, 2000);
      } else if (res.data && res.data.error) {
        // Handle API error response
        console.error("API Error:", res.data.message);
        alert(`Error: ${res.data.message}`);
      } else {
        // Unexpected success response
        console.warn("Unexpected API Response:", res);
        handleAddWebPopupClose();
        setShowAddWebPopup(false);
        setStatus((prev) => !prev);
        setCreateCallingPackage({
          PackageName: data?.offer,
          PackageDuration: data?.duration,
          CallDuration: "",
          PackageCost: "",
          Members: "",
          CallJoinUsers: "",
          PersonalMeetings: "",
          Meetings: "",
          Discount: "",
          Website: "",
          CountryName: ""
        });
      }
    } catch (err) {
      // Handle Axios errors
      if (err.response) {
        // Server responded with an error status code
        console.error("Error Response:", err.response);

        setUpdateError(err.response.data.message || "Something went wrong.")
      } else if (err.request) {
        // Request was made but no response received
        console.error("No Response:", err.request);
        setUpdateError("No response from the server. Please try again.")
      } else {
        // Other errors (e.g., network issues, invalid config)
        console.error("Error:", err.message);
        setUpdateError(`Error: ${err.message}`);
      }
    }
  };

  return (
    <div className="modal fade bd-example-modal-lg container w-75">
      <Modal
        show={showAddWebPopup}
        onHide={handleAddWebPopupClose}
        centered
        className="match-share-modal payment-modal"
      >
        <Modal.Header closeButton>
          <div className="w-100 text-center mt-4">
            <h6>
              {openPackageData === "Add Logo"
                ? "Add Logo"
                : openPackageData === "Add Offer text"
                  ? "Add Offer Text"
                  : "Create Package"}
            </h6>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 px-4">
            <div>
              <div className="small-font ms-1">
                Select Website <span className="red-clr">*</span>
              </div>
              <select className="w-100  small-font login-inputs input-btn-bg  my-2 p-2 all-none rounded all-none"
                name="Website"
                value={createCallingPackage.Website}
                onChange={handleInputChangeCalling}>
                <option value="" className="small-font">
                  Select
                </option>
                <option value="we2call.com" className="small-font">
                  we2call.com
                </option>
                <option value="Txchange" className="small-font">
                  Txchange
                </option>
              </select>
            </div>
            <div>
              <div className="small-font ms-1">
                Country <span className="red-clr">*</span>
              </div>
              <select className="w-100  small-font login-inputs input-btn-bg my-2 p-2 all-none rounded all-none"
                value={createCallingPackage.CountryName}
                onChange={handleInputChangeCalling}
                name="CountryName">
                <option value="" className="small-font">
                  Select
                </option>
                <option value="INDIA" className="small-font">
                  India{" "}
                </option>
                <option value="DUBAI" className="small-font">
                  Dubai
                </option>
              </select>
            </div>
            {/* <div className="w-100 d-flex">
              <Col className="w-50">
                <div>
                  <div className="medium-font mb-2 clr-grey">
                    Start Date <span className="red-clr">*</span>
                  </div>
                  <div className="w-100 small-font input-btn-bg px-2 py-1 all-none rounded all-none d-flex flex-row align-items-center"
                    onClick={toggleDate}>
                  

                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Date"
                      className="input-btn-bg all-none "
                      ref={dateRef}
                    />
                    <FaRegCalendarAlt className="upload-icon p-1 font-size-30" onClick={toggleDate} />
                  </div>
                </div>
              </Col>
              <Col className="w-50 ms-2">
                <div>
                  <div className="medium-font mb-2 clr-grey">
                    End Date <span className="red-clr">*</span>
                  </div>
                  <div className="w-100 small-font input-btn-bg px-2 py-1 all-none rounded all-none d-flex flex-row align-items-center"
                    onClick={toggleToDate}>

                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Date"
                      className="input-btn-bg all-none "
                      ref={dateToRef}
                    />
                    <FaRegCalendarAlt className="upload-icon p-1 font-size-30" onClick={toggleToDate} />
                  </div>
                </div>
              </Col>
            </div> */}
            {openPackageData === "Add Logo" ? (
              <>
                <div className="pointer">
                  <div className="small-font ms-1 my-2 pointer">
                    Upload Logo <span className="red-clr">*</span>
                  </div>
                  <label
                    className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2 pointer"
                    htmlFor="upload-logo"
                  >
                    Select
                    <IoIosCloudUpload />
                  </label>

                  <div>
                    <input
                      type="file"
                      id="upload-logo"
                      className=" display-none"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center w-100 mt-3 mb-4">
                  <button
                    className="add-button rounded px-2 py-2 w-50 medium-font"
                    onClick={
                      handleCreateWebsite
                    }
                  >
                    SUBMIT
                  </button>
                </div>
              </>
            ) : openPackageData === "Add Offer text" ? (
              <>
                <div>
                  <div className="small-font ms-1 my-2">
                    Enter Text <span className="red-clr">*</span>
                  </div>

                  <div>
                    <input
                      type="text"
                      id="upload-logo"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center w-100 mt-3 mb-4">
                  <button
                    className="add-button rounded px-2 py-2 w-50 medium-font"
                    onClick={() => {
                      handleSuccessPopup()
                      handleCreateWebsite()
                    }}
                  >
                    SUBMIT
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="w-100 d-flex">
                  <div className="w-50 ">
                    <div className="small-font ms-1 my-2">
                      Package Hour <span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        name="CallDuration"
                        inputMode="numaric"
                        value={createCallingPackage.CallDuration}
                        onChange={handleInputChangeCalling}
                      />
                    </div>
                  </div>
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Price <span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        inputMode="numeric"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        name="PackageCost"
                        value={createCallingPackage.PackageCost}
                        onChange={handleInputChangeCalling}
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   if (/^\d*$/.test(value)) {
                      //     handleOnchange(value);
                      //   }
                      // }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-50 ">
                    <div className="small-font ms-1 my-2">
                      Members <span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        name="Members"
                        inputMode="numeric"
                        value={createCallingPackage.Members}
                        onChange={handleInputChangeCalling}
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   if (/^\d*$/.test(value)) {
                      //     handleOnchange(value);
                      //   }
                      // }}
                      />
                    </div>
                  </div>
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Call join users <span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        name="CallJoinUsers"
                        inputMode="numeric"
                        value={createCallingPackage.CallJoinUsers}
                        onChange={handleInputChangeCalling}
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   if (/^\d*$/.test(value)) {
                      //     handleOnchange(value);
                      //   }
                      // }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-50 ">
                    <div className="small-font ms-1 my-2">
                      Personal meetings <span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        name="PersonalMeetings"
                        inputMode="numeric"
                        value={createCallingPackage.PersonalMeetings}
                        onChange={handleInputChangeCalling}
                      // value={price}
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   if (/^\d*$/.test(value)) {
                      //     handleOnchange(value);
                      //   }
                      // }}
                      />
                    </div>
                  </div>
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Meetings<span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        name="Meetings"
                        inputMode="numeric"
                        value={createCallingPackage.Meetings}
                        onChange={handleInputChangeCalling}
                      // value={price}
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   if (/^\d*$/.test(value)) {
                      //     handleOnchange(value);
                      //   }
                      // }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex">
                  {/* Discount Input */}
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Discount <span className="red-clr">*</span>
                    </div>
                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter discount"
                        name="Discount"
                        inputMode="numeric"
                        value={createCallingPackage.Discount}
                        onChange={handleInputChangeCalling}
                      />
                    </div>
                  </div>

                  {/*<div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Upload Logo/File <span className="red-clr">*</span>
                    </div>
                    <div>
                      <label
                        htmlFor="file-upload"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex align-items-center justify-content-between all-none rounded mt-2"
                      >
                        {singedUrl ? (
                          <span>{"File Uploaded"}</span>
                        ) : (
                          <span>Choose a file</span>
                        )}
                        <span className="clr-blue">Browse</span>
                      </label>
                      <input
                        type="file"
                        id="file-upload"
                        className="d-none"
                        accept="image/*"
                        onChange={
                          handleFileUpload}
                      />
                    </div>
                  </div>*/}
                </div>
                <div className="d-flex justify-content-center w-100 mt-3 mb-4">
                  <button
                    className="add-button rounded px-2 py-2 w-50 medium-font"
                    onClick={() => {
                      // handleSuccessPopup()
                      handleCreateCallingPackage()
                    }}
                  >
                    CREATE
                  </button>
                  <span className="red-clr">{updateApiError}</span>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <MatchSubmitPopup
        header={"Website Added Successfully"}
        state={acceptClick}
        setState={setAcceptClick}
        setStatus={setStatus}
        lineLiveCreation={lineLiveCreation}
        setLineLiveCreation={setLineLiveCreation}
        createPackage={"Creation Successfully"}

      />
    </div>
  );
}

export default PackagePopup;