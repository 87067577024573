// import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { app_base_url, app_base_url_2, Signed_url, app_base_url_3 } from "./config_local";

const axios = require("axios");
const base_url = app_base_url;
const base_url_instance = app_base_url_2;

export const call = async (
  config = { method: "GET" },
  data = {},
  params = {}
) => {
  let url = base_url + config.url;

  if (config.method === "GET" && data && Object.keys(data).length > 0) {
    url += "?";
    Object.entries(data).forEach(([key, value]) => {
      url += `${key}=${value}&`;
    });
  }
  if (params && Object.keys(params).length > 0) {
    url += "?";
    Object.entries(params).forEach(([key, value]) => {
      url += `${key}=${value}&`;
    });
  }
  if (url.substr(url.length - 1, url.length) === "&") {
    url = url.slice(0, url.length - 1);
  }
  const options = {
    method: config.method,
    url,
    mode: "cors",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    timeout: 60000,
    headers: {
      Authorization: localStorage.getItem("token")?.replace(/\"/g, ""),
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJTeXN0ZW1Vc2VyIiwianRpIjoiZmJlOTRkMmUtNmY2Ny00YTdjLWI1MmUtMTU1YjI5MTU0MjJmIiwiZXhwIjoxNzYzODAyNTgxLCJpc3MiOiJ3ZTJjYWxsLmNvbSIsImF1ZCI6IndlMmNhbGwuY29tIn0.ixECVifpdslPK1gQKimR88Pgsf4mehAqZbDImjbnu1c`
    },
  };
  if (["POST", "PUT", "DELETE"].indexOf(config.method) > -1) {
    options.data = data;
  }
  return axios(options);
};
export const call_to_access = async (
  config = { method: "GET" },
  data = {},
  params = {}
) => {
  let url = app_base_url_3 + config.url;

  if (config.method === "GET" && data && Object.keys(data).length > 0) {
    url += "?";
    Object.entries(data).forEach(([key, value]) => {
      url += `${key}=${value}&`;
    });
  }
  if (params && Object.keys(params).length > 0) {
    url += "?";
    Object.entries(params).forEach(([key, value]) => {
      url += `${key}=${value}&`;
    });
  }
  if (url.substr(url.length - 1, url.length) === "&") {
    url = url.slice(0, url.length - 1);
  }
  const options = {
    method: config.method,
    url,
    mode: "cors",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    timeout: 60000,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJTeXN0ZW1Vc2VyIiwianRpIjoiZmJlOTRkMmUtNmY2Ny00YTdjLWI1MmUtMTU1YjI5MTU0MjJmIiwiZXhwIjoxNzYzODAyNTgxLCJpc3MiOiJ3ZTJjYWxsLmNvbSIsImF1ZCI6IndlMmNhbGwuY29tIn0.ixECVifpdslPK1gQKimR88Pgsf4mehAqZbDImjbnu1c`
    },
  };
  if (["POST", "PUT", "DELETE"].indexOf(config.method) > -1) {
    options.data = data;
    console.log(options, options.data)
  }
  return axios(options);
};


export const Signed = async (
  config = { method: "GET" },
  data = {},
  params = {}
) => {
  let url = Signed_url + config.url;

  if (config.method === "GET" && data && Object.keys(data).length > 0) {
    url += "?";
    Object.entries(data).forEach(([key, value]) => {
      url += `${key}=${value}&`;
    });
  }
  if (params && Object.keys(params).length > 0) {
    url += "?";
    Object.entries(params).forEach(([key, value]) => {
      url += `${key}=${value}&`;
    });
  }
  if (url.substr(url.length - 1, url.length) === "&") {
    url = url.slice(0, url.length - 1);
  }
  const options = {
    method: config.method,
    url,
    mode: "cors",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    timeout: 60000,
    headers: {
      Authorization: localStorage.getItem("token")?.replace(/\"/g, ""),
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJTeXN0ZW1Vc2VyIiwianRpIjoiZmJlOTRkMmUtNmY2Ny00YTdjLWI1MmUtMTU1YjI5MTU0MjJmIiwiZXhwIjoxNzYzODAyNTgxLCJpc3MiOiJ3ZTJjYWxsLmNvbSIsImF1ZCI6IndlMmNhbGwuY29tIn0.ixECVifpdslPK1gQKimR88Pgsf4mehAqZbDImjbnu1c`
    },
  };
  if (["POST", "PUT", "DELETE"].indexOf(config.method) > -1) {
    // options.data = JSON.stringify(data);
    options.data = data;
  }
  return axios(options);
};


const S3_BUCKET = "t2t";
const REGION = "ap-southeast-1";

// AWS.config.update({
//   accessKeyId: "AKIA3ZQZV5XBHJRBMBYI",
//   secretAccessKey: "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",
//   region: REGION,
// });

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// });

// export const uploadFilesToS3 = (files, setProgress) => {
//   return Promise.all(
//     files.map((file) => {
//       return new Promise((resolve, reject) => {
//         const uniqueKey = `${Date.now()}-${uuidv4()}-${file.name}`;
//         const params = {
//           ACL: "public-read",
//           Body: file,
//           Bucket: S3_BUCKET,
//           Key: uniqueKey,
//         };
//         console.log(file.type, "type content")
//         myBucket
//           .putObject(params)
//           .on("httpUploadProgress", (evt) => {
//             const progress = Math.round((evt.loaded / evt.total) * 100);
//             // setProgress((prevProgress) => ({
//             //   ...prevProgress,
//             //   [file.name]: progress,
//             // }));
//           })
//           .send((err) => {
//             if (err) {
//               reject(err);
//             } else {
//               const fileUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${uniqueKey}`;
//               resolve(fileUrl);
//             }
//           });
//       });
//     })
//   );
// };

export const axiosInstance = axios.create({
  baseURL: app_base_url_2,
  headers: {
    'Content-Type': 'application/json',
  },
});


axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  },
  (error) => Promise.reject(error)
);
